// Add your custom JS here.

jQuery(function($) {

  //共通
  var headerH = $("#wrapper-navbar").outerHeight(true);//headerの高さを取得

  // 画面をスクロールをしたら動かしたい場合の記述
  $(window).scroll(function () {
    FixedAnime();//スクロール途中からヘッダーの高さを変化させる関数を呼ぶ
  });

  //----- スクロールの途中から固定ヘッダーにする
  //スクロール途中からヘッダーの高さを変化させるための設定を関数でまとめる
  function FixedAnime() {
    //ヘッダーの高さを取得
    var scroll = $(window).scrollTop();
    if (scroll >= headerH){//ヘッダーの高さを超えたら
          $('#wrapper-navbar').addClass('HeightMin');//#wrapper-navbarについているHeightMinというクラス名を付与
    }else{
          $('#wrapper-navbar').removeClass('HeightMin');//HeightMinというクラス名を除去
    }
  }

  //----- ページトップボタン
  $(function () {
    const pageTop = $("#page-top");
    pageTop.hide();
    $(window).scroll(function () {
      if ($(this).scrollTop() > 300) {
        pageTop.fadeIn();
      } else {
        pageTop.fadeOut();
      }
    });
    pageTop.click(function () {
      $("body,html").animate(
        {
          scrollTop: 0,
        },
        500
      );
      return false;
    });
  });

});
